import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import {graphql, withPrefix} from 'gatsby'
import SEO from '../../components/seo'
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import Image from "../../components/Image";

const StatePage = ({ data }) => {
  const {statePage: page} = data;

  const crumbs = [
    {pathname: "/", crumbLabel: "Home"},
    {pathname: "/studentcolleges.html", crumbLabel: "Colleges"},
    {pathname: withPrefix(page.path), crumbLabel: page.title}
  ];

  return (
      <Layout>
        <SEO title={page.metaTags.title} robots={page.metaTags.robots} />

        <CustomBreadcrumbs crumbs={crumbs}/>

        <h1>{page.title}</h1>

        { !!page.image && <Image imgName={page.image} /> }

        <div className="page-body" dangerouslySetInnerHTML={{__html: page.body}}/>
      </Layout>
  )
};

StatePage.propTypes = {
  data: PropTypes.shape({
    statePage: PropTypes.object
  })
};

export default StatePage

export const pageQuery = graphql`
    query($id: String!) {
        statePage(nid: {eq: $id}) {
            title
            metaTags {
                title
                robots
            }
            body
            path
            image
        }
    }
`;
